import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const NotFoundPage = () => {
  return (
    <div className="container">
      <div className="bold">404</div>
      <div className="bold">页面找不到</div>
      <Link to="/" replace>
        回到首页
      </Link>
    </div>
  );
};

export default NotFoundPage;
