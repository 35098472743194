import Header from "../../components/header";
import Footer from "../../components/footer";
import Overview from "../overview";
import "./index.scss";

const Home = () => {
  return (
    <div className="home">
      <Header />
      <Overview />
      <Footer />
    </div>
  );
};

export default Home;
