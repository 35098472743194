import { Affix, Popover, Button } from "antd";
import { QRCodeSVG } from "qrcode.react";
import logo from "../../images/logo.png";
import miniProgramCode from "../../images/miniProgramCode.png";
import "./index.scss";

const Header = () => {
  return (
    <Affix>
      <div className="header">
        <img src={logo} alt="logo" className="headerLogo" />
        <div className="download">
          <div className="downloadButton">
            <Popover
              placement="bottom"
              content={
                <img
                  src={miniProgramCode}
                  className="miniProgramCode"
                  alt="miniProgramCode"
                />
              }
            >
              <Button type="primary">顾客端微信小程序</Button>
            </Popover>
          </div>
          <div className="downloadButton">
            <Popover
              placement="bottom"
              content={
                <div className="downloadPopover">
                  <div className="downloadItem">
                    <QRCodeSVG
                      value="https://testflight.apple.com/join/frBWNuEB"
                      size={100}
                    />
                    <div>IOS</div>
                  </div>
                  <div className="downloadItem">
                    <QRCodeSVG
                      value={`https://www.bojueyou.com.cn/androidDownload?timestamp=${new Date().getTime()}`}
                      size={100}
                    />
                    <div>Android</div>
                  </div>
                </div>
              }
            >
              <Button type="primary">管家端APP</Button>
            </Popover>
          </div>
          <div className="downloadButton">
            <Button
              type="primary"
              href="https://console.bojueyou.com.cn"
              target={"_blank"}
            >
              管家端管理台
            </Button>
          </div>
        </div>
      </div>
    </Affix>
  );
};

export default Header;
