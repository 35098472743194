import { useState, useEffect } from "react";
import { Button } from "antd";
import PIC_LOGO from "../../images/logo2.png";
import { configData } from "./config.js";
import "./index.scss";

const AndroidDownloadPage = () => {
  const [tipVisible, setTipsVisible] = useState(false);

  useEffect(() => {
    var ua = window.navigator.userAgent.toLowerCase();
    if (/MicroMessenger/i.test(ua)) {
      //微信浏览器
      setTipsVisible(true);
    }
  }, []);

  return (
    <div className="androidContainer">
      <div className="downloadContainer">
        {tipVisible && (
          <div className="tips">
            <div>如无法下载，点击右上角</div>
            <div>选择"浏览器打开"</div>
          </div>
        )}
        <img src={PIC_LOGO} alt="logo" className="logo" />
        <div className="title">铂爵跟拍</div>
        <div className="separator"></div>
        <div className="version">版本号：{configData.version}</div>
        <div>更新于：{configData.date}</div>
        <Button
          shape="round"
          type="primary"
          href="https://bjy-public-1312487572.cos.ap-shanghai.myqcloud.com/apk/release.apk"
          size="large"
          className="downloadButton"
        >
          下载安装
        </Button>
        <div className="separator"></div>
        <div className="contentTitle">更新日志</div>
        <div className="contentContainer">
          {configData.content.map((item, index) => {
            return <div key={index}>{item}</div>;
          })}
        </div>
      </div>
    </div>
  );
};

export default AndroidDownloadPage;
