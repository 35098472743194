/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PNG_ARCHIVE from "../../images/archive.png";
import "./index.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div>
        Copyright © 2023 Bojueyou (Xiamen) Technology Co., Ltd. All Rights
        Reserved
        <div style={{ margin: "0 auto", padding: "10px 0" }}>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35021302000579"
            style={{
              display: "inline-block",
              texteDcoration: "none",
              height: "20px",
              lineHeight: "20px",
            }}
          >
            <img src={PNG_ARCHIVE} style={{ float: "left" }} alt="" />
            <p
              style={{
                float: "left",
                height: "20px",
                lineHeight: "20px",
                margin: "0px 0px 0px 5px",
                color: "#939393",
              }}
            >
              闽公网安备 35021302000579号
            </p>
          </a>

          <a
            style={{
              display: "inline-block",
              texteDcoration: "none",
              height: "20px",
              lineHeight: "20px",
              marginLeft: "20px",
            }}
            href="https://beian.miit.gov.cn/"
            target="_blank"
          >
            <p
              style={{
                float: "left",
                height: "20px",
                lineHeight: "20px",
                margin: "0px 0px 0px 5px",
                color: "#939393",
              }}
            >
              闽ICP备20012940号-1
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
