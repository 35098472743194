import { Carousel } from "antd";
import cooperation from "../../images/cooperation.jpg";
import customers from "../../images/customers.jpg";
import ideas from "../../images/ideas.jpg";
import ideas2 from "../../images/ideas2.jpg";
import indemnification from "../../images/indemnification.jpg";
import brandIntroduction from "../../images/brandIntroduction.jpg";
import mission from "../../images/mission.jpg";
import productMatrix from "../../images/productMatrix.jpg";
import profile from "../../images/profile.jpg";
import propostion from "../../images/propostion.jpg";
import slogan from "../../images/slogan.jpg";
import studio from "../../images/studio.jpg";
import tourismCity from "../../images/tourismCity.jpg";
import "./index.scss";

const Overview = () => {
  return (
    <>
      <div className="image-container">
        <img src={studio} alt="studio" />
      </div>
      <div className="image-container">
        <img src={slogan} alt="slogan" />
      </div>
      <div className="image-container">
        <img src={ideas} alt="ideas" />
      </div>
      <div className="image-container">
        <img src={ideas2} alt="ideas2" />
      </div>

      <Carousel
        className="carousel-container"
        dots={{ className: "carousel-dot" }}
        autoplay
      >
        <div className="image-container">
          <img src={productMatrix} alt="productMatrix" />
        </div>
        <div className="image-container">
          <img src={propostion} alt="propostion" />
        </div>
        <div className="image-container">
          <img src={customers} alt="customers" />
        </div>
        <div className="image-container">
          <img src={tourismCity} alt="tourismCity" />
        </div>
      </Carousel>

      <Carousel
        className="carousel-container"
        dots={{ className: "carousel-dot" }}
        autoplay
      >
        <div className="image-container">
          <img src={mission} alt="mission" />
        </div>
        <div className="image-container">
          <img src={brandIntroduction} alt="brandIntroduction" />
        </div>
        <div className="image-container">
          <img src={profile} alt="profile" />
        </div>
        <div className="image-container">
          <img src={indemnification} alt="indemnification" />
        </div>
        <div className="image-container">
          <img src={cooperation} alt="cooperation" />
        </div>
      </Carousel>
    </>
  );
};

export default Overview;
