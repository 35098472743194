import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/home";
import NotFoundPage from "../pages/404";
import AndroidDownload from "../pages/android-download";
import OverviewPage from "../pages/overview";

const PhotoSamplePage = React.lazy(() => import("../pages/photo-sample"));

const routeList = createBrowserRouter([
  { path: "/", element: <Navigate to="/home/overview" replace /> },
  {
    path: "/home",
    element: <HomePage />,
    children: [
      {
        path: "overview",
        element: <OverviewPage />,
      },
    ],
  },
  {
    path: "/photoSample",
    element: <PhotoSamplePage />,
  },
  {
    path: "/androidDownload",
    element: <AndroidDownload />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default routeList;
